import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TurnedInRoundedIcon from "@material-ui/icons/TurnedInRounded";
import { appContext } from "../../App";
import AddIcon from "@material-ui/icons/Add";
import { numberWithCommas, AVATAR_DONEE, HOME_PAGE } from "../../utils/utils";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import Box from "@material-ui/core/Box";
import "./Sidemenu.scss";
import { Tooltip } from "@material-ui/core";
import Organization from "../Organization/Organization";
import Contacts from "../Contacts/Contacts";
import ContactGroups from "../ContactGroups/ContactGroups";
import ChatRoomForm from "../ChatRooms/ChatRoomForm";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalpaper: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
    // borderRadius: 3,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: 260,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 260,
  },
}));

const SideMenu = (props) => {
  const {
    authUser,
    userSummary,
    setAvatar,
    isSuperAdmin,
    isAdmin,
    isFinAdmin,
    isTALRadioAdmin,
    isVolunteer,
    orgList,
    contacts,
  } = useContext(appContext);
  const classes = useStyles();
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const [radioMenu, setRadioMenu] = useState("");
  const [totalRequestCount, setTotalRequestCount] = useState(0);
  const handleEditProfile = () => {
    setRadioMenu("");
    props.history.push(`/newUserProfile/${authUser.unique_id}`);
  };

  useEffect(() => {
    if (userSummary) {
      let total = 0;
      userSummary &&
        userSummary.myApplications &&
        userSummary.myApplications.requestTypes &&
        userSummary.myApplications.requestTypes.forEach((requestType) => {
          if (
            ["scholarship", "career", "help", "bloodDonation"].includes(
              requestType._id
            )
          ) {
            total += requestType.count;
          }
        });
      setTotalRequestCount(total);
    }
  }, [userSummary]);

  const handleMyRequests = () => {
    setRadioMenu("");
    setAvatar(AVATAR_DONEE);
    props.history.push(HOME_PAGE);
  };
  const handleMyDonations = () => {
    setRadioMenu("");
    props.history.push("/myDonations");
  };
  const handleMyWithdrawals = () => {
    setRadioMenu("");
    props.history.push("/myWithdrawals");
  };
  const handleMyOrganizations = () => {
    setRadioMenu("");
    props.history.push("/myOrganizations");
  };
  const showFavourites = () => {
    setRadioMenu("");
    props.history.push("/myfavourites");
  };

  const CreateChatSession = () => {
    setRadioMenu("");
    props.history.push("/createAudioRoom");
  };

  const displayChatRooms = (id) => {
    setRadioMenu("");
    props.history.push("/chatTabs");
  };

  const handleTALRadioMenu = (id) => {
    setRadioMenu(id);
    props.history.push(`/talradioAdmin/manageTALRadio/tal-radio/${id}`);
  };
  const handleMyContactGroups = () => {
    setRadioMenu("");
    props.history.push("/myContactsandGroups");
  };
  const handleMyApplications = () => {
    setRadioMenu("");
    props.history.push("/myApplications");
  };

  const handleParticipant = (val) => {
    if (val == "registrations") {
      props.history.push("/ManageEventParticipants");
    } else {
      props.history.push("/ManageEventPayments");
    }
  };
  if (authUser && authUser.unique_id) {
    return (
      <div className="sidemenu-container tal_radio_menu">
        <div className="profile-block drop-box">
          <Paper className={classes.paper + " no-pad"} elevation={0}>
            <div className="user-details-cont">
              <div className="user-details">
                <p className="user-name">
                  <img
                    src={
                      authUser && authUser.profile_image_url !== ""
                        ? authUser.profile_image_url
                        : "/images/no-photo-available.png"
                    }
                    alt="user profile"
                    className="user-profile-image"
                  />
                  <p>
                    <span style={{ fontSize: "14px" }}>
                      Welcome, {authUser && authUser.name.first_name}
                    </span>
                    <span onClick={handleEditProfile}>Edit Profile</span>
                  </p>
                </p>
              </div>
            </div>
          </Paper>

          <Paper className={classes.paper + " no-pad"} elevation={0}>
            <div className="personal-links-cont">
              <ul>
                <li style={{ fontWeight: 500 }}>
                  <span>Kindness Score</span>
                  <span style={{ color: "#000" }}>
                    {userSummary && numberWithCommas(userSummary.kindnessScore)}
                  </span>
                </li>
                <li onClick={handleMyRequests}>
                  <span>My Requests</span>
                  <span>
                    {userSummary &&
                      userSummary.donationRequests &&
                      userSummary.donationRequests.total}
                  </span>
                </li>
                <li onClick={handleMyDonations}>
                  <span>My Donations</span>
                  <span>
                    {userSummary &&
                      userSummary.donations &&
                      userSummary.donations.total}
                  </span>
                </li>
                <li onClick={handleMyWithdrawals}>
                  <span>My Withdrawals</span>
                  <span>
                    {userSummary &&
                      userSummary.withdrawals &&
                      userSummary.withdrawals.total}
                  </span>
                </li>
                <li onClick={handleMyOrganizations}>
                  <span>My Organizations</span>
                  <span>{orgList && orgList.length}</span>
                </li>
                <li onClick={handleMyContactGroups}>
                  <span>My Contacts and Groups</span>
                  <span>{contacts && contacts.length}</span>
                </li>
                <li onClick={handleMyApplications}>
                  <span>My Applications</span>
                  <span>{totalRequestCount}</span>
                </li>
                <li onClick={showFavourites}>
                  <span>Favourites</span>
                  <span>
                    {userSummary &&
                      userSummary.favorites &&
                      userSummary.favorites.total}
                  </span>
                </li>
              </ul>
            </div>
          </Paper>

          <Paper className={classes.paper + " no-pad"} elevation={0}>
            <div className="saved-req-blk">
              <p
                className="saved-req-links"
                onClick={() => {
                  window.open(`/user/${authUser.username}`);
                }}
                style={{ cursor: "pointer" }}
              >
                View my public profile
              </p>
            </div>
          </Paper>
        </div>
        {isAdmin && (
          <div className="admin-block drop-box">
            <ListItem>
              <div style={{ display: "flex" }}>
                <ListItemText primary="TALKindness Day 2023" />
              </div>
            </ListItem>
            <div className={"talradio-nav-options"}>
              <List
                component="nav"
                className="admin-nav-options"
                aria-labelledby="nested-list-subheader"
              >
                <ListItem
                  button
                  style={{
                    background:
                      radioMenu === "liveStreamings"
                        ? "rgba(205, 39, 41, 0.1)"
                        : "",
                  }}
                  onClick={(e) => handleParticipant("registrations")}
                >
                  <div style={{ display: "flex" }}>
                    <ListItemIcon>
                      <TurnedInRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Registrations"
                      style={{
                        color: radioMenu === "liveStreamings" ? "red" : "",
                      }}
                    />
                  </div>
                </ListItem>
              </List>
              {/* <List
                component="nav"
                className="admin-nav-options"
                aria-labelledby="nested-list-subheader"
              >
                <ListItem
                  button
                  style={{
                    background:
                      radioMenu === "liveStreamings"
                        ? "rgba(205, 39, 41, 0.1)"
                        : "",
                  }}
                  onClick={(e) => handleParticipant("payments")}
                >
                  <div style={{ display: "flex" }}>
                    <ListItemIcon>
                      <TurnedInRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Payments"
                      style={{
                        color: radioMenu === "liveStreamings" ? "red" : "",
                      }}
                    />
                  </div>
                </ListItem>
              </List> */}
            </div>
          </div>
        )}
        <div className="side-Organization admin-block drop-box">
          <div className="myOrganizations">
            <Box component="div" className="side-box">
              <Box component="p">Audio Rooms</Box>
              <Button className="drop-box">
                <Tooltip title="Create A New Audio Room">
                  <AddIcon onClick={CreateChatSession} />
                </Tooltip>
              </Button>
            </Box>

            <Box
              component="div"
              style={{ cursor: "pointer", padding: "5px 20px" }}
              onClick={() => displayChatRooms()}
            >
              View Ongoing and Future Rooms
            </Box>
          </div>
        </div>

        {(isSuperAdmin || isAdmin || isVolunteer) && (
          <div className="admin-block drop-box">
            <ListItem>
              <div style={{ display: "flex" }}>
                <ListItemText primary="Admin Functionality" />
              </div>
            </ListItem>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={"admin-nav-options"}
            >
              <ListItem
                button
                onClick={(e) => props.history.push("/admin/donationRequests")}
              >
                <ListItemIcon>
                  <TurnedInRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Donation Requests" />
              </ListItem>
              <ListItem
                button
                onClick={(e) => props.history.push("/admin/withdrawalRequests")}
              >
                <ListItemIcon>
                  <TurnedInRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Withdrawal Requests" />
              </ListItem>
              <ListItem
                button
                onClick={(e) => props.history.push("/admin/organization")}
              >
                <ListItemIcon>
                  <TurnedInRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Organizations" />
              </ListItem>
              <div
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={"admin-nav-options"}
              >
                <ListItem
                  button
                  onClick={(e) => props.history.push("/admin/userActivityLog")}
                >
                  <ListItemIcon>
                    <TurnedInRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Activity Logs" />
                </ListItem>
              </div>
              <div
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={"admin-nav-options"}
              >
                <ListItem
                  button
                  onClick={(e) => props.history.push("/admin/userList")}
                >
                  <ListItemIcon>
                    <TurnedInRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Users List" />
                </ListItem>
              </div>
              <div
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={"admin-nav-options"}
              >
                <ListItem
                  button
                  onClick={(e) => props.history.push("/admin/bloodDonors")}
                >
                  <ListItemIcon>
                    <TurnedInRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Blood Donors" />
                </ListItem>
              </div>
              <div
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={"admin-nav-options"}
              >
                <ListItem
                  button
                  onClick={(e) => props.history.push("/admin/SendEmailtoUsers")}
                >
                  <ListItemIcon>
                    <TurnedInRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Send email to users/contacts" />
                </ListItem>
              </div>
            </List>
          </div>
        )}

        {isAdmin && (
          <div className="admin-block drop-box">
            <ListItem>
              <div style={{ display: "flex" }}>
                <ListItemText primary="Manage TALLeaders" />
              </div>
            </ListItem>
            <List
              component="nav"
              className="admin-nav-options"
              aria-labelledby="nested-list-subheader"
            >
              <ListItem
                button
                onClick={(e) =>
                  props.history.push("/admin/TALLeaders/userInterests")
                }
              >
                <ListItemIcon>
                  <TurnedInRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Manage User Interests" />
              </ListItem>
            </List>
          </div>
        )}

        {isTALRadioAdmin && (
          <div className="admin-block drop-box">
            <ListItem>
              <div style={{ display: "flex" }}>
                <ListItemText primary="Manage TALRadio/TALMedia" />
              </div>
            </ListItem>
            <div className={"talradio-nav-options"}>
              <List
                component="nav"
                className="admin-nav-options"
                aria-labelledby="nested-list-subheader"
              >
                <ListItem
                  button
                  style={{
                    background:
                      radioMenu === "liveStreamings"
                        ? "rgba(205, 39, 41, 0.1)"
                        : "",
                  }}
                  onClick={(e) => handleTALRadioMenu("liveStreamings")}
                >
                  <div style={{ display: "flex" }}>
                    <ListItemIcon>
                      <TurnedInRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Live Streamings"
                      style={{
                        color: radioMenu === "liveStreamings" ? "red" : "",
                      }}
                    />
                  </div>
                </ListItem>
                <ListItem
                  button
                  style={{
                    background:
                      radioMenu === "managePlaylist"
                        ? "rgba(205, 39, 41, 0.1)"
                        : "",
                  }}
                  onClick={(e) => handleTALRadioMenu("managePlaylist")}
                >
                  <div style={{ display: "flex" }}>
                    <ListItemIcon>
                      <TurnedInRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Manage Playlists"
                      style={{
                        color: radioMenu === "managePlaylist" ? "red" : "",
                      }}
                    />
                  </div>
                </ListItem>
                <ListItem
                  button
                  style={{
                    background:
                      radioMenu === "managePlaylistItems"
                        ? "rgba(205, 39, 41, 0.1)"
                        : "",
                  }}
                  onClick={(e) => handleTALRadioMenu("managePlaylistItems")}
                >
                  <div style={{ display: "flex" }}>
                    <ListItemIcon>
                      <TurnedInRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Manage Playlist Items"
                      style={{
                        color: radioMenu === "managePlaylistItems" ? "red" : "",
                      }}
                    />
                  </div>
                </ListItem>
                <ListItem
                  button
                  style={{
                    background:
                      radioMenu === "categories"
                        ? "rgba(205, 39, 41, 0.1)"
                        : "",
                  }}
                  onClick={(e) => handleTALRadioMenu("categories")}
                >
                  <div style={{ display: "flex" }}>
                    <ListItemIcon>
                      <TurnedInRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Categories"
                      style={{
                        color: radioMenu === "categories" ? "red" : "",
                      }}
                    />
                  </div>
                </ListItem>
                <ListItem
                  button
                  style={{
                    background:
                      radioMenu === "statisticsReport"
                        ? "rgba(205, 39, 41, 0.1)"
                        : "",
                  }}
                  onClick={(e) => handleTALRadioMenu("statisticsReport")}
                >
                  <div style={{ display: "flex" }}>
                    <ListItemIcon>
                      <TurnedInRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Generate Playlist Report"
                      style={{
                        color: radioMenu === "statisticsReport" ? "red" : "",
                      }}
                    />
                  </div>
                </ListItem>
                <ListItem
                  button
                  style={{
                    background:
                      radioMenu === "BMImusicsReport"
                        ? "rgba(205, 39, 41, 0.1)"
                        : "",
                  }}
                  onClick={(e) => handleTALRadioMenu("BMImusicsReport")}
                >
                  <div style={{ display: "flex" }}>
                    <ListItemIcon>
                      <TurnedInRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary=" Generate BMI Music Report"
                      style={{
                        color: radioMenu === "BMImusicsReport" ? "red" : "",
                      }}
                    />
                  </div>
                </ListItem>
                <ListItem
                  button
                  onClick={(e) => props.history.push("/admin/managePodcast")}
                >
                  <ListItemIcon>
                    <TurnedInRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manage Podcast" />
                </ListItem>
                <ListItem
                  button
                  onClick={(e) => props.history.push("/admin/Channels")}
                >
                  <ListItemIcon>
                    <TurnedInRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manage Channels" />
                </ListItem>

                {/* Do not delete */}
                {/* <ListItem
                      button
                      style={{
                        background:
                          radioMenu === "totalRJs"
                            ? "rgba(205, 39, 41, 0.1)"
                            : "",
                      }}
                      onClick={(e) => handleTALRadioMenu("totalRJs")}
                    >
                      <ListItemText
                        primary="Total RJ's"
                        style={{
                          color: radioMenu === "totalRJs" ? "red" : "",
                        }}
                      />
                    </ListItem> */}
              </List>
            </div>
          </div>
        )}

        {isFinAdmin && (
          <div className="admin-block drop-box">
            <ListItem>
              <div style={{ display: "flex" }}>
                <ListItemText primary="Financial Admin  Functionality" />
              </div>
            </ListItem>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={"admin-nav-options"}
            >
              <ListItem
                button
                onClick={(e) => props.history.push("/admin/donations")}
              >
                <ListItemIcon>
                  <TurnedInRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Donations" />
              </ListItem>
              <ListItem
                button
                onClick={(e) => props.history.push("/admin/withdrawalRequests")}
              >
                <ListItemIcon>
                  <TurnedInRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Withdrawal Requests" />
              </ListItem>
              <ListItem
                button
                onClick={(e) => props.history.push("/admin/donationRequests")}
              >
                <ListItemIcon>
                  <TurnedInRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Donation Requests" />
              </ListItem>
              <ListItem
                button
                onClick={(e) =>
                  props.history.push("/admin/reconciliationReport")
                }
              >
                <ListItemIcon>
                  <TurnedInRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Reconciliation Report" />
              </ListItem>
              <ListItem
                button
                onClick={(e) => props.history.push("/admin/userList")}
              >
                <ListItemIcon>
                  <TurnedInRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Users List" />
              </ListItem>
            </List>
          </div>
        )}

        {/* {!isVolunteer && (
          <div className="vol-block drop-box">
            <p>Be a superhero!</p>
            <p>Students can volunteer to be TALScout</p>
            <Button
              onClick={() => {
                props.history.push("/become-a-volunteer");
              }}
            >
              Apply here
            </Button>
          </div>
        )} */}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default withRouter(SideMenu);
