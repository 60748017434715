import axios from "axios";
import firebase from "../firebase/firebase";

export const login = (values) => {
  const finalValues = values;
  finalValues.dontSendToken = true;
  return new Promise((resolve, reject) => {
    axios
      .post("/login", finalValues)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const signup = (values) => {
  const finalValues = values;
  finalValues.dontSendToken = true;
  return new Promise((resolve, reject) => {
    axios
      .post("/register", values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const resendOTP = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/resendotp", values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateProfile = (userId, values) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/user/${userId}`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const changeUsername = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/change/username`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const authorize = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/oautherize", values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({ message: "Authentication failed." + error.message });
      });
  });
};

export const forgotPassword = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/forgotpassword", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const changePassword = (value) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/changepassword", value)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "/changepassword Failed. " + error.message });
      });
  });
};

export const validateOtp = (otpDetails) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/verifyotp", otpDetails)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserLocaleInfo = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/miscellaneousconfig/ipinfo`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "getUserLocaleInfo Failed. " + error.message });
      });
  });
};

export const getDonationRequests = (
  region = "",
  limit = 15,
  offset = 0,
  user_id,
  requestType,
  keyword = "",
  status = "1,2",
  zeroFunds,
  noWithdrawalRequests,
  isprivate,
  loginId = null,
  source,
  isTaxBenefit,
  isUrgentRequirement
) => {
  return new Promise((resolve, reject) => {
    let url = `/donationRequest?limit=${limit}&offset=${offset}`;
    if (keyword) {
      url = url + `&keyword=${keyword}`;
    }
    if (region) {
      url = url + `&region=${region}`;
    }
    if (user_id) {
      url = url + `&user_id=${user_id}`;
    }
    if (loginId) url = url + `&loginId=${loginId}`;
    if (source) {
      url = url + `&sourceOfCreation=${source && source.trim()}`;
    }
    if (status) {
      if (keyword) {
        // include expired status as well
        status = status + ",-4";
      }
      url = url + `&status=${status}`;
    } else {
      if (keyword) {
        status = "0,1,2,-4";
      } else {
        status = "0,1,2";
      }
      url = url + `&status=${status}`;
    }
    if (requestType) {
      url = url + `&requestType=${requestType}`;
    }
    if (zeroFunds) {
      url = url + `&zeroFunds=${zeroFunds}`;
    }
    if (noWithdrawalRequests) {
      url = url + `&noWithdrawalRequests=${noWithdrawalRequests}`;
    }
    if (isprivate) {
      url = url + `&isPrivate=${isprivate}`;
    }
    if (isTaxBenefit) {
      url = url + `&isTaxBenefit=${isTaxBenefit}`;
    }
    if (isUrgentRequirement) {
      url = url + `&isUrgentRequirement=${isUrgentRequirement}`;
    }
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "getDonationRequests Failed. " + error.message });
      });
  });
};

export const getAllDonationRequests = (
  limit = 10,
  offset = 0,
  region,
  requestType,
  status,
  source,
  zeroFunds,
  noWithdrawalRequests,
  isprivate,
  keyword,
  isDownload = false,
  isTaxBenefit,
  isUrgentRequirement
) => {
  return new Promise((resolve, reject) => {
    let url = `/donationRequest?limit=${limit}&offset=${offset}&sortBy=createdAt&sortOrder=-1`;
    if (region) {
      url = url + `&region=${region}`;
    }
    if (requestType) {
      url = url + `&requestType=${requestType}`;
    }
    if (status) {
      url = url + `&status=${status}`;
    }
    if (source) {
      url = url + `&sourceOfCreation=${source && source.trim()}`;
    }
    if (zeroFunds) {
      url = url + `&zeroFunds=${zeroFunds}`;
    }
    if (noWithdrawalRequests) {
      url = url + `&noWithdrawalRequests=${noWithdrawalRequests}`;
    }
    if (isprivate) {
      url = url + `&isPrivate=${isprivate}`;
    }
    if (keyword) {
      url = url + `&keyword=${keyword}`;
    }
    if (isDownload) {
      url = url + `&isDownload=${isDownload}`;
    }
    if (isTaxBenefit) {
      url = url + `&isTaxBenefit=${isTaxBenefit}`;
    }
    if (isUrgentRequirement) {
      url = url + `&isUrgentRequirement=${isUrgentRequirement}`;
    }
    axios
      .get(url, {
        dontShowLoader: true,
      })
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "getDonationRequests Failed. " + error.message });
      });
  });
};

export const getUserDonationRequests = (
  userId,
  status,
  region,
  limit = 10,
  offset = 0,
  isPrivate = true,
  requestType,
  loginId = null
) => {
  return new Promise((resolve, reject) => {
    let url;
    if (status) {
      url = `/donationRequest?limit=${limit}&offset=${offset}&user_id=${userId}&status=${status}&my_search=true&sortBy=createdAt&sortOrder=-1`;
    } else {
      url = `/donationRequest?limit=${limit}&offset=${offset}&user_id=${userId}&my_search=true&sortBy=createdAt&sortOrder=-1`;
    }
    if (region) {
      url = url + `&region=${region}`;
    }
    if (requestType) {
      url = url + `&requestType=${requestType}`;
    }
    url = url + `&isPrivate=${isPrivate}`;
    if (loginId) url = url + `&loginId=${loginId}`;
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "getUserDonationRequests Failed. " + error.message });
      });
  });
};

export const getOrgDonationRequests = (
  orgId,
  status,
  region,
  limit = 10,
  offset = 0,
  requestType,
  loginId = null,
  userId = null
) => {
  return new Promise((resolve, reject) => {
    let url;
    if (status) {
      url = `/donationRequest?limit=${limit}&offset=${offset}&orgId=${orgId}&status=${status}`;
    } else {
      url = `/donationRequest?limit=${limit}&offset=${offset}&orgId=${orgId}`;
    }
    if (requestType) {
      url = url + `&requestType=${requestType}`;
    }
    if (region) {
      url = url + `&region=${region}`;
    }
    if (userId) {
      url = url + `&user_id=${userId}`;
    }
    if (loginId) {
      url = url + `&loginId=${loginId}`;
    }
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "getOrgDonationRequests Failed. " + error.message });
      });
  });
};

export const getDonationRequest = (donationRequestId, userId) => {
  return new Promise((resolve, reject) => {
    let url = `/donationRequest/${donationRequestId}`;
    if (userId) {
      url = url + `?userId=${userId}`;
    }
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const getDonationRequestComments = (donationRequestId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/donation/request/comment?requestId=${donationRequestId}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({ message: "Could not fetch comments. " + error.message });
      });
  });
};

export const createDonationRequestComment = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/donation/request/comment", values)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({ message: "Comment creation failed. " + error.message });
      });
  });
};

export const getUserDonations = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/donation?user_id=${userId}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({ message: "get Donations Failed. " + error.message });
      });
  });
};

export const getDonationRequestData = (
  userId,
  limit = 10,
  offset = 0,
  download = false
) => {
  let url = `/donation/user/${userId}?limit=${limit}&offset=${offset}`;
  if (download) {
    url = url + `&isDownload=${download}`;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "get Donations Failed. " + error.message });
      });
  });
};

export const saveDonation = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/donation", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Saving of Donation Failed. " + error.message });
      });
  });
};
export const eventDonation = (values, token) => {
  return new Promise((resolve, reject) => {
    const headers = {};
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    axios
      .post("/eventDonation", values, {
        headers,
      })
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Saving of Donation Failed. " + error.message });
      });
  });
};

// export const chargeDonation = (payload) => {
//   console.log("values", payload);
//   return new Promise((resolve, reject) => {
//     const { token, ...values } = payload;
//     const config = {
//       headers: { Authorization: `Bearer ${token}` },
//     };
//     axios
//       .post("/stripe/charge", values, config)
//       .then((result) => {
//         if (result) {
//           resolve(result.data);
//         }
//       })
//       .catch((error) => {
//         reject(error.response);
//       });
//   });
// };

export const chargeDonation = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/stripe/charge", payload)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const createDonationRequest = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/donationRequest", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateDonationRequest = (values, donationRequestId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/donationRequest/${donationRequestId}`, values)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteDonationRequest = (donationRequestId, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/donationRequest/${donationRequestId}?user_id=${userId}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({ message: "delete donation request Failed. " + error.message });
      });
  });
};

export const deleteComment = (requestId, userId, commentId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/delete/request/comment`, {
        data: { requestId: requestId, userId: userId, commentId: commentId },
      })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({ message: "delete comment Failed. " + error.message });
      });
  });
};

export const editComment = (comment, userId, commentId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/edit/request/comment`, {
        comment: comment,
        userId: userId,
        commentId: commentId,
      })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({ message: "edit comment Failed. " + error.message });
      });
  });
};
export const validateUser = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/searchuser", email)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "/searchuser Failed. " + error.message });
      });
  });
};

export const getUserData = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/${userId}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({
          message: "User data could not be obtained. " + error.message,
        });
      });
  });
};

export const getUserDataWithUsername = (username) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/profile/${username}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({
          message: "User data could not be obtained. " + error.message,
        });
      });
  });
};
// User Or Org
export const getUserOrOrgName = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/userOrOrgName/${id}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({
          message: "User or Org data could not be obtained. " + error.message,
        });
      });
  });
};

export const favourite = (obj) => {
  obj.dontShowLoader = true;
  return new Promise((resolve, reject) => {
    axios
      .post("/donation/request/favourite", obj)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: error.message,
        });
      });
  });
};

export const likeOrUnlike = (obj) => {
  obj.dontShowLoader = true;
  return new Promise((resolve, reject) => {
    axios
      .post("/donation/request/like", obj)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: error.message,
        });
      });
  });
};

export const getVolunteers = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/volunteerAdmin?user_id=${userId}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({
          message: "TALScout list could not be obtained. " + error.message,
        });
      });
  });
};

export const getMyFavRequests = (userId, limit = 50, offset = 0) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/donationRequest?user_id=${userId}&fav_search=true&limit=${limit}&offset=${offset}`
      )
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: error.message,
        });
      });
  });
};

export const updateVolunteerApplicationStatus = (userId, newStatus) => {
  return new Promise((resolve, reject) => {
    axios
      .put("/updateVolunteerAdminStatus", {
        userIds: [userId],
        status: newStatus,
      })
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({
          message: "TALScout status could not be updated. " + error.message,
        });
      });
  });
};

export const getUserSummary = (userId, requestType = null) => {
  return new Promise((resolve, reject) => {
    let url = `/user/summary/${userId}`;
    if (requestType) {
      url = url + `?requestType=${requestType}`;
    }
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject({
          message: "Failed to get user summary data. " + error.message,
        });
      });
  });
};

export const changeNumber = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/change/number", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const changeCurrentPassword = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/change/user/password", values)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const accountInformation = (values, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/user/${userId}/payment/account`, values)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const donationRequestUpdate = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/donationRequestUpdate`, values)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDonationRequestUpdate = (requestId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/donationRequestUpdate/?requestId=${requestId}`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({
          message: error.message,
        });
      });
  });
};

export const editDonationRequestUpdate = (uniqueId, values) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/donationRequestUpdate/${uniqueId}`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({
          message: error.message,
        });
      });
  });
};

export const deleteDonationRequestUpdate = (uniqueId, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/donationRequestUpdate/${uniqueId}`, {
        data: { userId: userId },
      })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({
          message: error.message,
        });
      });
  });
};

export const getAccountInfo = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/${userId}/payment/account`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({
          message: error.message,
        });
      });
  });
};

export const updateAccountInformation = (accountId, userId, values) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/user/${userId}/payment/account/${accountId}`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({
          message: error.message,
        });
      });
  });
};

export const getRegions = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/region")
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({
          message: error.message,
        });
      });
  });
};

export const getCategories = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/category")
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({
          message: error,
        });
      });
  });
};

export const getDonorsList = (requestId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/donation?request_id=${requestId}&sortBy=quantity&limit=1000`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({
          message: error,
        });
      });
  });
};

export const fetchAllPlaylist = (filters = {}, type = "") => {
  return new Promise((resolve, reject) => {
    const startDate = filters.startDate;
    const endDate = filters.endDate;
    const channel = filters.channel;
    const apipath = type === "bmi" ? "bmiReport" : "getStatisticsReport";

    axios
      .get(
        `talradio/playlistItem/${apipath}?startDate=${startDate}&endDate=${endDate}&channel=${channel}`
      )
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({
          message: error,
        });
      });
  });
};

export const getAllCountries = (supportedCountries) => {
  return new Promise((resolve, reject) => {
    let url = `/country`;
    if (supportedCountries) {
      url = url + `?supportedCountries=${supportedCountries}`;
    }
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: error,
        });
      });
  });
};

export const getAllStates = (countryId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/state?countryId=${countryId}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: error,
        });
      });
  });
};

export const getAllCities = (stateId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/city?stateId=${stateId}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: error,
        });
      });
  });
};

export const getTransactionDetails = (donationId, query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/transactionDetails?donationId=${donationId}&donationfor=${query}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: error,
        });
      });
  });
};

export const postFeedback = (content) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/notifications/email", content)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: error,
        });
      });
  });
};

export const checkImage = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/obscene/image", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: error,
        });
      });
  });
};

export const checkVideo = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_APP_ENGINE_BASE_URL}/obscene/video`,
        values
      )
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: error,
        });
      });
  });
};

export const manageAccountInfo = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/disband/user", values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const getUserNotifications = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/notifications?userId=${userId}`, {
        params: { dontShowLoader: true },
      })
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};
// ORGANIZATION API's

export const addOrganization = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/organization", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateNotification = (userid, notificationid) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/notifications/${notificationid}`, {
        status: 1,
        userId: userid,
        dontShowLoader: true,
        // notificationIds: [notificationid]
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

// Org Bank Account related API's

export const createOrgBankAccount = (values, orgId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/org/${orgId}/payment/account`, values)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const getOrgAccountInfo = (orgId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/org/${orgId}/payment/account`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({
          message: error.message,
        });
      });
  });
};

export const updateOrgBankAccountInformation = (accountId, orgId, values) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/org/${orgId}/payment/account/${accountId}`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({
          message: error.message,
        });
      });
  });
};

export const deleteOrgBankAccount = (orgId, accountId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/org/${orgId}/payment/account/${accountId}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Account deletion failed. " + error.message });
      });
  });
};

export const deleteUserNotification = (id, userid) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/notifications/${id}`, {
        data: { userId: userid },
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const getAllOrganizations = (
  download = false,
  limit = 15,
  offset = 0,
  keyword = ""
) => {
  return new Promise((resolve, reject) => {
    let url = `organization`;
    if (download) {
      url = `organization?isDownload=${download}`;
    } else {
      url = `/organization?limit=${limit}&offset=${offset}`;
    }
    if (keyword) {
      url = url + `&keyword=${keyword}`;
    }
    axios
      .get(url)
      .then((result) => {
        if (result && download) {
          resolve(result.data);
        } else {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getOrganizationList = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/org/user/${userId}`, {
        params: { dontShowLoader: false },
      })
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getOrgdetails = (orgId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/organization/${orgId}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteOrganization = (orgId, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/organization/${orgId}`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({ message: "Delete organization Failed. " + error.message });
      });
  });
};

export const updateOrganizationStatus = (orgId, obj) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/organization/${orgId}`, obj)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const inviteMember = (orgId, obj) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/org/${orgId}/inviteUser`, obj)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getOrgMemberList = (orgId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/org/${orgId}/user`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addOrganisationUser = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/org/${data.setOrgId}/user`, data)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addOrganisationUserWithToken = (bodyParameters, config) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/org/${bodyParameters.setOrgId}/user`, bodyParameters, config)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateUserUsingToken = (userId, payload, config) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/user/${userId}`, payload, config)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const removeUser = (orgId, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/org/${orgId}/user/${userId}`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({ message: "Delete user Failed. " + error.message });
      });
  });
};

export const changeUserRole = (orgId, userId, values) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/org/${orgId}/user/${userId}`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const changeMobileNumber = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/change/number`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEmailTemplates = (category = "donor") => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/emailTemplate?category=${category}`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getContactEmailTemplates = (orgId) => {
  return new Promise((resolve, reject) => {
    let url = `/emailTemplate`;
    if (orgId) {
      url = url + `?orgId=${orgId}`;
    }
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSelectedEmailTemplate = (templateId, orgId) => {
  return new Promise((resolve, reject) => {
    let url = `/emailTemplate/${templateId}`;
    if (orgId) {
      url = url + `?orgId=${orgId}`;
    }
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const launchEmailCampaign = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/emailCampaignLauncher`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const launchContactEmailCampaign = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/contactEmailCampaignLauncher`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getMyContacts = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/${userId}/contact`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addContact = (userId, contact) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/user/${userId}/contact`, contact)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const addContacts = (userId, contacts) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/user/${userId}/contacts`, contacts)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateContact = (userId, contactId, contact) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/user/${userId}/contact/${contactId}`, contact)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteContact = (userId, contactId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/user/${userId}/contact/${contactId}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getMyContactGroups = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/${userId}/contactGroup`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getContactGroupById = (userId, groupId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/${userId}/contactGroup/${groupId}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addContactGroup = (userId, cg) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/user/${userId}/contactGroup/`, cg)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const assignContactToGroup = (userId, contactId, groupId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/user/${userId}/contact/${contactId}/group/${groupId}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const unAssignGroupToContact = (userId, contactId, groupId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/user/${userId}/contact/${contactId}/group/${groupId}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const updateContactGroup = (userId, cgId, cg) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/user/${userId}/contactGroup/${cgId}`, cg)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const deleteContactGroup = (userId, cgId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/user/${userId}/contactGroup/${cgId}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const assignContactsToGroup = (userId, groupId, contactIdList) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/user/${userId}/contact/group/${groupId}`, {
        contactIds: contactIdList,
      })
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const unAssignContactsToGroup = (userId, groupId, contactIdList) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/user/${userId}/contact/group/${groupId}/unassign`, {
        contactIds: contactIdList,
      })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getGroupsByContactId = (userId, cgId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/${userId}/group/contact?contactId=${cgId}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getContactsByGroupId = (userId, groupId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/${userId}/group/contact?groupId=${groupId}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Get the Previous donors list for the donation requests created by the givn user
export const getTALDonorsContacts = (userId, orgId) => {
  return new Promise((resolve, reject) => {
    let url = `/donationRequest/donors?userId=${userId}`;
    if (orgId && orgId._id) {
      url = url + `&orgId=${orgId._id}`;
    }
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDonationsByFilter = (
  query = "",
  limit = 10,
  offset = 0,
  download = false
) => {
  return new Promise((resolve, reject) => {
    let url = `/donation?`;
    if (query) {
      url = url + `${query}&limit=${limit}&offset=${offset}`;
    }
    if (download) {
      url = url + "&isDownload=" + download;
    }

    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: error.message,
        });
      });
  });
};

export const getPayUHash = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/payu`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const PayUPaymentSuccess = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/payu/payment/success`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const CareerHelpShareDetails = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`notifications/careerHelp/shareContactDetails`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getStreamings = (id, limit = 5, offset = 0, channel) => {
  return new Promise((resolve, reject) => {
    let url;
    if (id) {
      url = `/streaming/${id}`;
    } else {
      url = `/streaming?limit=${limit}&offset=${offset}&channel=${channel}&sortBy=startTime&sortOrder=-1`;
    }

    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Streamings Failed. " + error.message });
      });
  });
};
export const saveStreaming = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/streaming", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: error.message });
      });
  });
};

export const updateStreaming = (id, values) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/streaming/${id}`, values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteStreaming = (streamingId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/streaming/${streamingId}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Streaming delete failed. " + error.message });
      });
  });
};
export const getPlaylists = (id, limit = 10, offset = 0, channel, keyword) => {
  return new Promise((resolve, reject) => {
    let url;
    if (id) {
      url = `/talradio/playlist/${id}`;
    } else {
      url = `/talradio/playlist?limit=${limit}&offset=${offset}&channel=${channel}`;
    }
    if (keyword) {
      url = `/talradio/playlist?keyword=${keyword}&limit=${limit}&offset=${offset}&channel=${channel}`;
    }
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Problem in fetching Playlists:" + error.message });
      });
  });
};

export const savePlaylist = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("talradio/playlist", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const validateScheduleDates = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/streaming/validateDates", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updatePlaylist = (id, values) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`talradio/playlist/${id}`, values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deletePlaylist = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`talradio/playlist/${id}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Playlist delete failed. " + error.message });
      });
  });
};

// TAL Media
export const getMediaDocuments = async (
  limit = 10,
  pageToken,
  language = "all"
) => {
  const db = firebase.db.collection("/podcasts/");
  let query = null;
  if (!pageToken && language == "all") {
    query = db.orderBy("time", "desc").limit(limit);
  } else if (pageToken && language == "all") {
    query = db.orderBy("time", "desc").startAfter(pageToken).limit(limit);
  } else if (pageToken) {
    query = db
      .where("language", "==", `${language}`)
      .orderBy("time", "desc")
      .startAfter(pageToken)
      .limit(limit);
  } else {
    query = db
      .where("language", "==", `${language}`)
      .orderBy("time", "desc")
      .limit(limit);
  }
  let totalCountOfRecords = 0;
  if (language === "all") {
    await db
      .get()
      .then((snapshot) => (totalCountOfRecords = snapshot.docs.length));
  } else {
    await db
      .where("language", "==", `${language}`)
      .get()
      .then((snapshot) => (totalCountOfRecords = snapshot.docs.length));
  }

  return new Promise((resolve, reject) => {
    const posts = [];
    query
      .get()
      .then((snapshot) => {
        const pageToken = snapshot.docs[snapshot.docs.length - 1];
        snapshot.forEach((post) => {
          posts.push({ ...post.data(), id: post.id });
        });
        resolve({
          data: posts,
          nextPageToken: pageToken,
          totalCountOfRecords,
        });
      })
      .catch((error) => {
        reject({ message: "Media Fetching Failed. " + error });
      });
  });
};

export const getFileInfo = (fileName) => {
  return new Promise((resolve, reject) => {
    let url = `/talradio/getFileInfo/${fileName}`;
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({ message: "Media File Fetching Failed. " + error.message });
      });
  });
};

//User Summary

export const UserSummary = (isKindnessEventRegistered2023) => {
  return new Promise((resolve, reject) => {
    let url;
    if (isKindnessEventRegistered2023) {
      url = `/user/signupReportSummary?isKindnessEventRegistered2023=${true}`;
    } else {
      url = `/user/signupReportSummary`;
    }

    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Users Total count Fetch Failed. " + error.message });
      });
  });
};

export const getUsers = (
  query,
  limit = 10,
  offset = 10,
  roles = null,
  download,
  isKindnessEventRegistered2023,
  isTalTalksRegistration2024,
  isBloodDonor
) => {
  return new Promise((resolve, reject) => {
    let url = `/users?limit=${limit}&offset=${offset}`;
    if (query) url += `&${query}`;
    if (roles) {
      url = url + "&roles=" + roles;
    }
    if (download) {
      url = url + "&isDownload=" + download;
    }
    if (isKindnessEventRegistered2023) {
      url = url + "&isKindnessEventRegistered2023=true";
    }
    if (isTalTalksRegistration2024) {
      url = url + "&isTalTalksRegistration2024=true";
    }
    if (isBloodDonor) {
      url = url + "&isBloodDonor=true";
    }
    axios
      .get(url, { dontShowLoader: true })
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Users List Fetch Failed. " + error.message });
      });
  });
};

export const getTALRadioCategories = (id) => {
  return new Promise((resolve, reject) => {
    let url;
    if (id) {
      url = `/talradio/categories/${id}`;
    } else {
      url = `/talradio/categories?orderBy=name`;
    }
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Categories Failed. " + error.message });
      });
  });
};

export const saveRadioCategory = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/talradio/categories", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const updateRadioCategory = (id, values) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/talradio/categories/${id}`, values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteCategory = (recordId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/talradio/categories/${recordId}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Category delete failed. " + error.message });
      });
  });
};

export const inviteRJ = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/user/invite`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const addRJWithToken = (bodyParameters, config) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/user/auth`, bodyParameters, config)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const withdrawRequest = (id, values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/donationRequest/withdraw/${id}`, values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateWithdrawRequest = (values, id) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/donationRequest/withdraw/${id}`, values)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getDonationDetails = (id) => {
  return new Promise((resolve, reject) => {
    let url = `/donationRequest/${id}`;
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "gettDonationDetails  Failed. " + error.message });
      });
  });
};

export const getWithdrawRequests = (
  id,
  requestId = null,
  download = false,
  query,
  status
) => {
  return new Promise((resolve, reject) => {
    let url = `/donationRequest/withdraw?sortBy=createdAt&sortOrder=-1`;
    if (id) {
      url += `&userId=${id}`;
    }
    if (requestId) {
      url += `&requestId=${requestId}`;
    }
    if (query) {
      url = url + `&${query}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    if (download) {
      url += `&isDownload=${download}`;
    }
    axios
      .get(url)
      .then((result) => {
        if (result && download) {
          resolve(result.data);
        } else {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({ message: "getWithdrawRequests Failed. " + error.message });
      });
  });
};

// ***** START PLAYLIST ITEM APIS ****** ////

export const getCurrentStreaming = (channel) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/talradio/currentStreaming?channel=${channel}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({ message: "getCurrentStreaming Failed. " + error.message });
      });
  });
};

export const getCurrentTime = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/currentTime`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({ message: "get currentTime Failed. " + error.message });
      });
  });
};

export const getActivityLog = (query, id) => {
  return new Promise((resolve, reject) => {
    let url = `/userActivityLog?sortOrder=-1&${query}`;
    if (id) {
      url = `/userActivityLog/${id}`;
    }
    axios
      .get(url, {
        dontShowLoader: true,
      })
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "userActivityLog Failed. " + error.message });
      });
  });
};

export const VerifyOrganizationEmailMessage = (orgId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/organization/verify/${orgId}`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getChannels = (includeAll = false) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/talradio/channels?includeAll=${includeAll}`, {
        dontShowLoader: true,
      })
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Channels Fetching Failed. " + error.message });
      });
  });
};

export const saveChannel = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/talradio/channels", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: error.message });
      });
  });
};

export const updateChannel = (id, values) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/talradio/${id}`, values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteChannel = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/talradio/${id}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Channel delete failed. " + error.message });
      });
  });
};

// PUSH NOTIFICATION - Add Device Token
export const addPushNotificationDeviceToken = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/pushNotificationDeviceToken", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: error.message });
      });
  });
};

export const sendPushNotificationToAll = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/pushNotificationDeviceToken/sendToAll", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: error.message });
      });
  });
};

export const sendEmailToAllUsers = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/adminEmailCampaignLauncher", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: error.message });
      });
  });
};
export const applyDonationRequest = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/donationRequestResponses", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const applyBloodDonationRequest = (values, id = "") => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/donationRequestResponses/${id}`, values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getDonationRequestResponse = (id, userId) => {
  return new Promise((resolve, reject) => {
    let url = `/donationRequestResponses?`;
    if (id) url += `donationRequestId=${id}`;
    if (userId) url += `userId=${userId}`;
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({ message: "getCurrentStreaming Failed. " + error.message });
      });
  });
};
export const saveChatRooms = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("chatRooms", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: error.message });
      });
  });
};

export const updateChatRooms = (id, values) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/chatRooms/${id}`, values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteChatRooms = (chatRoomId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/chatRooms/${chatRoomId}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "ChatRooms delete failed. " + error.message });
      });
  });
};

export const getChatRooms = (inputData = {}) => {
  const { uniqueId = "" } = inputData;
  return new Promise((resolve, reject) => {
    axios
      .get(`/chatRooms/${uniqueId}?sortOrder=1`, {
        dontShowLoader: true,
      })
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "ChatRoom data Fetching Failed. " + error.message });
      });
  });
};

export const getPodcasts = (
  language,
  category,
  limit = 10000,
  offset = 0,
  keyword
) => {
  let url = `/talradio/media?limit=${limit}&offset=${offset}`;
  if (language) {
    url = url + `&language=${language}`;
  }
  if (category) {
    url = url + `&categoryId=${category}`;
  } else {
    if (keyword) {
      url = `/talradio/media?keyword=${keyword}&limit=${limit}&offset=${offset}&language=${language} `;
    }
  }

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "getPodcasts Failed. " + error.message });
      });
  });
};

export const getPodcastById = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/talMediaShare/${id}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "getPodcast  by Id Failed. " + error.message });
      });
  });
};

export const savePodcast = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/talmediaPodcast", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: error.message });
      });
  });
};

export const updatePodcast = (id, values) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/talradio/media/${id}`, values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deletePodcast = (podcastId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/talradio/media/${podcastId}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Podcast delete failed. " + error.message });
      });
  });
};

//verify ACH payment
export const verifyBankAccount = (accountId, values) => {
  return new Promise((resolve, reject) => {
    if (!accountId || !values) {
      reject({ message: "AccountId / Values are missing" });
    } else {
      axios
        .post(`stripe/verify-bank-account/${accountId}`, values, {
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        })
        .then((result) => {
          if (result && result.status == 200) {
            resolve(result.data);
          } else {
            reject(result.data);
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    }
  });
};

export const deleteAccount = (userId, accountId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/user/${userId}/payment/account/${accountId}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "ACH Account delete failed. " + error.message });
      });
  });
};

export const saveShortUrl = (url) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/shorturl", url)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({ message: error.message });
      });
  });
};

export const getUserInterestedCategories = (id) => {
  return new Promise((resolve, reject) => {
    let url;
    if (id) {
      url = `/userInterested/categories/${id}`;
    } else {
      url = `/userInterested/categories?orderBy=name`;
    }
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: "Getting User Interests Failed. " + error.message,
        });
      });
  });
};

export const saveUserInterestedCategory = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/userInterested/categories", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateUserInterestedCategory = (id, values) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/userInterested/categories/${id}`, values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteUserInterestedCategory = (recordId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/userInterested/categories/${recordId}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: "User Interested Category deletion failed. " + error.message,
        });
      });
  });
};

export const razorpayCreateOrder = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/razorpay/createOrder`, values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const razorpayVerifyPayment = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/razorpay/payment/verify`, values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const googleReCaptchaVerify = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/auth/verify-captcha`, values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getYouTubeVideos = (
  channelId,
  nextPageToken,
  rowsPerPage = 32
) => {
  return new Promise((resolve, reject) => {
    fetch(
      `https://youtube.googleapis.com/youtube/v3/search?part=snippet&channelId=${channelId}&maxResults=${rowsPerPage}&order=date&key=${process.env.REACT_APP_FIREBASE_API_KEY}&pageToken=${nextPageToken}`
    )
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateDonationRequestAnalytics = (id, action) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/donationRequest/analytics/${id}/${action}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getReconciliationReport = (
  download = false,
  limit = 10,
  offset = 0,
  status
) => {
  return new Promise((resolve, reject) => {
    let url = `donationRequest/reconciliationReport`;
    if (download) {
      url = `donationRequest/reconciliationReport?isDownload=${download}`;
    } else {
      url = `donationRequest/reconciliationReport?limit=${limit}&offset=${offset}`;
    }
    if (status) {
      url = url + `&status=${status}`;
    }
    axios
      .get(url)
      .then((result) => {
        if (result && download) {
          resolve(result.data);
        } else {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDonorList = ({
  keyword = null,
  limit = null,
  email = null,
  country = null,
  city = null,
  state = null,
}) => {
  let url = `/user/donor?limit=${limit}`;
  if (keyword) {
    url = url + `&keyword=${keyword}`;
  }
  if (country) {
    url = url + `&country=${country}`;
  }
  if (city) {
    url = url + `&city=${city}`;
  }
  if (state) {
    url = url + `&state=${state}`;
  }
  if (email) {
    url = url + `&email=${email}`;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDoneeList = ({
  limit = null,
  keyword = null,
  email = null,
  country = null,
  city = null,
  state = null,
}) => {
  let url = `/user/donee?limit=${limit}`;
  if (keyword) {
    url = url + `&keyword=${keyword}`;
  }
  if (country) {
    url = url + `&country=${country}`;
  }
  if (city) {
    url = url + `&city=${city}`;
  }
  if (state) {
    url = url + `&state=${state}`;
  }
  if (email) {
    url = url + `&email=${email}`;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const talEventRegistration = (values) => {
  const finalValues = values;
  finalValues.dontSendToken = true;
  return new Promise((resolve, reject) => {
    axios
      .post("/kindnessDayRegistration", values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const verifyCouponCode = (values) => {
  return new Promise((resolve, reject) => {
    let url = `/coupons?couponCode=${values.couponCode}`;
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const geEventUsers = (
  query,
  limit = 10,
  offset = 10,
  roles = null,
  download,
  isKindnessEventRegistered2023
) => {
  return new Promise((resolve, reject) => {
    let url = `/eventUsersList?limit=${limit}&offset=${offset}`;
    if (query) url += `${query}`;
    if (roles) {
      url = url + "&roles=" + roles;
    }
    if (download) {
      url = url + "&isDownload=" + download;
    }
    if (isKindnessEventRegistered2023) {
      url = url + "&isKindnessEventRegistered2023=true";
    }
    axios
      .get(url, { dontShowLoader: true })
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Users List Fetch Failed. " + error.message });
      });
  });
};

export const getEventUsersList = (event) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/eventUsersList?event=${event}&isDownload=true`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: "Getting event users list Failed. " + error.message,
        });
      });
  });
};
