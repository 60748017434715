import React, { useContext, useEffect } from "react";
import { Button, MenuItem, Select } from "@material-ui/core";
import { appContext } from "../../App";
import { checkDefaultChannel } from "../../utils/utils";

function ChannelSelectionDDBox(props) {
  const {
    setPlaylists,
    channel,
    setChannel,
    channels,
    setTalmediaLanguage,
    currentChannel,
  } = useContext(appContext);

  function handleChangeChannel(newChannel) {
    const currentChannel = newChannel || null;
    if (props.onChannelChange) {
      props.onChannelChange(channel, currentChannel);
    }
    setTalmediaLanguage(checkDefaultChannel(currentChannel));
    setChannel(currentChannel);
    if (props.setOffset) props.setOffset(0);
    if (props.setRows) props.setRows([]);
    if (props.setPlaylists) setPlaylists([]);
  }

  return (
    <>
      <div className="tal_radio_gl">
        <p>TALRadio</p>
        <div>
          <Button
            key={"talradio"}
            onClick={() => handleChangeChannel("talradio")}
          >
            English
          </Button>
          <Button key={"hindi"} onClick={() => handleChangeChannel("hindi")}>
            Hindi
          </Button>
          <Button key={"telugu"} onClick={() => handleChangeChannel("telugu")}>
            Telugu
          </Button>
        </div>
      </div>
    </>
  );
}
export default ChannelSelectionDDBox;
